<template>
  <div class="content_area containerBox">
    <div class="title">{{ $t('expoMeetingBrief') }}</div>
    <div class="content">
      <span class="colorE0F6FF">{{ $t('meetingBrief1_1') }}</span>
      <span>{{ $t('meetingBrief1_2') }}</span>
      <span class="colorE0F6FF">{{ $t('meetingBrief1_3') }}</span>
      <span>{{ $t('meetingBrief1_4') }}</span>
   </div>
    <div class="content marginT12">
      <span>{{ $t('meetingBrief2_1') }}</span>
      <span class="colorE0F6FF">{{ $t('meetingBrief2_2') }}</span>
      <span>{{ $t('meetingBrief2_3') }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "brief",
    data() {
      return {}
    }
  }
</script>

<style scoped lang="less">
.containerBox {
  padding: 40px 0 60px;
  .title {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;

  }
  .content {
    color: #333333;
    font-size: 16px;
    text-indent:2em;
  }
  .marginT12 {
    margin-top: 12px;
  }
  .colorE0F6FF {
    background-color: #E0F6FF;
  }
}
</style>