<template>
  <div class="content_area containerBox">
    <div class="title">{{$t('hotExhibitor')}}</div>
    <div class="list">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <el-image :fit="'scale-down'" :src="img_url" class="coverImg"></el-image>
        <div class="footBox">
          <div class="companyName" :title="item.name"><span class="point" @click="handleCompany(item)">{{ item.name }}</span></div>
          <div class="flexBox">
            <div class="color717171">
              {{ item.adress }}
            </div>
            <div class="color717171">{{$t('booth')}}：<span class="exhibitor">{{ item.id }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "hotExhibitor",
    data() {
      return {
        dataList: [
          {
            name: '链韵物流科技（上海）有限公司111111111111111111111111111',
            adress: '中国·大连',
            id: 'H-2A'
          },
          {
            name: '链韵物流科技（上海）有限公司',
            adress: '中国·大连',
            id: 'H-2A'
          },
          {
            name: '链韵物流科技（上海）有限公司',
            adress: '中国·大连',
            id: 'H-2A'
          },
          {
            name: '链韵物流科技（上海）有限公司',
            adress: '中国·大连',
            id: 'H-2A'
          },
        ],
        img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/qrCode.jpg'
      }
    },
    methods:{
      handleCompany(item) {
        alert('跳转到公司')
      }
    }
  }
</script>

<style scoped lang="less">
  .containerBox {
    padding-bottom:60px;
    .title {
      color: #333333;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 24px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      .item {
        width: 310px;
        height: 274px;
        border: 1px solid #E9E9E9;
        .coverImg {
          width: 310px;
          height: 186px;
        }
        .footBox {
          padding: 14px 16px 0;
          .companyName {
            width: 278px;
            color: #4C4C4C;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .flexBox {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            .exhibitor {
              color: #666666;
            }
          }
        }

      }
    }
    .color717171 {
      color: #717171;
    }
  }
</style>