<template>
  <div>
    <indexBanner></indexBanner>
    <div class="bannerBox" @click="goWeb" v-if="LOCALE === 'en'">
      <div class="bannerContent">
        <div
          class="bannerInner"
          :style="{ 'background-image': 'url(' + bannerenImg + ')' }"
        ></div>
      </div>
    </div>
    <div class="bannerBox boxZh" @click="goWeb" v-else>
      <div class="bannerContent">
        <div
          class="bannerInner"
          :style="{ 'background-image': 'url(' + banneImg + ')' }"
        ></div>
      </div>
    </div>
    <newsMove></newsMove>
    <div v-if="!wonderfulHide">
      <div class="title clearfix content_area">
        <div class="fl">{{ $t("wonderfulMemories") }}</div>
        <div class="more fr" @click="more">
          {{ $t("more") }}
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <wonderful @isWonderfulHide="isWonderfulHide"></wonderful>
    </div>
    <hotVideo></hotVideo>
    <hotBooth></hotBooth>
    <brief></brief>
    <brightExhibitor></brightExhibitor>

    <supportUnit></supportUnit>
    <!-- <div class="index1v1" v-show="isShow && LOCALE == 'zh'">
      <div class="indexIcon clearfix">
        <i class="el-icon-error fr cursor" @click="isShow = false"></i>
      </div>
      <el-image
        @click="to1v1Href"
        class="index1v1Img cursor"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/index1V1.png"
      ></el-image>
      <div class="tc">会场1v1洽谈预约入口</div>
    </div> -->
  </div>
</template>

<script>
import hotBooth from "~/baseComponents/baseMeetingIndex/hotBooth/index";
import hotVideo from "~/baseComponents/baseMeetingIndex/hotVideo/index";
import indexBanner from "~exp/components/home/indexBanner";
import banner from "~exp/components/common/banner";
import brief from "~exp/components/home/brief";
import hotExhibitor from "~exp/components/home/hotExhibitor";
import brightExhibitor from "~exp/components/home/brightExhibitor";
import newsMove from "~exp/components/home/newsMove";
import wonderful from "~exp/components/home/wonderful";
import supportUnit from "~exp/components/home/supportUnit";
export default {
  components: {
    hotVideo,
    indexBanner,
    banner,
    brief,
    hotExhibitor,
    brightExhibitor,
    newsMove,
    supportUnit,
    hotBooth,
    wonderful,
  },
  data() {
    return {
      isShow: true,
      wonderfulHide: false,

      banneImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/banne.png",
      bannerenImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/banneren.png",
    };
  },
  mounted() {
    this.checkSignEnrollExhibitor({
      todo: () => {
        // this.applyDialog = true;
      },
      type: "notBtn",
    });
  },
  methods: {
    goWeb() {
      // let now = moment("2021-4-17").endOf("day").unix()
      // console.log(now);
      // let a = moment("2021-04-17").startOf("day").unix()
      // console.log(a);
      // 当前时间
      let nowTime = moment(new Date()).unix();
      // 未开始时间,首页跳转报名详情
      let step1 = moment("2021-03-05").startOf("day").unix();
      // 2月20日到3月19日，跳投票页面
      let step2 = moment("2021-04-17").startOf("day").unix();
      // 如果当前的时间大于未开始的时间 使页面跳转到投票活动页面
      if (nowTime < step1) {
        this.$router.push("/activity");   // activity投票活动页面
        // 如果 step2 大于当前时间 并且 当前时间 大于 未开始时间
        // 使页面跳转到 "/list" 页面
      } else if (step2 > nowTime && nowTime > step1) {
        this.$router.push({
          path: "/list",
          query: {
            parameter: this._encode({
              type: 1,
            }),
          },
        });
        // 如果当前时间 大于 step2 跳转到 "/overallRankingList"
      } else if (nowTime > step2) {
        this.$router.push("/overallRankingList");
      }
    },
    more() {
      this.$router.push({
        path: "/moreWonderful",
      });
    },
    isWonderfulHide() {
      this.wonderfulHide = true;
    },
    goMeet() {
      let url =
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/MeetingGuide.pdf";
      window.open(url);
    },
    to1v1Href() {
      window.open("http://ffc.jctrans.com/Summit/Login.html");
    },
  },
};
</script>

<style lang="less" scoped>
.bannerBox {
  width: 100%;
  padding-top: 7%;
  position: relative;
  cursor: pointer;

  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    .bannerInner {
      height: 100%;
      background-size: 100% 100%;
      position: relative;
      transition: all 0.5s;
    }
    .bannerInner:hover {
      transform: scale(1.1);
    }
  }
}
.boxZh {
  margin-bottom: 50px;
}
.index1v1 {
  position: fixed;
  left: 53px;
  top: 50%;
  color: #1f292e;
  .index1v1Img {
    margin-bottom: 5px;
  }
  .indexIcon {
    font-size: 24px;
    color: #1f292e;
    opacity: 0.5;
  }
}
.title {
  margin-top: 50px;
  .fl {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;
  }
  .more {
    font-size: 15px;
    color: #999;
    cursor: pointer;
  }
}
</style>
