import { render, staticRenderFns } from "./newSlider.vue?vue&type=template&id=28bf8124&scoped=true&"
import script from "./newSlider.vue?vue&type=script&lang=js&"
export * from "./newSlider.vue?vue&type=script&lang=js&"
import style0 from "./newSlider.vue?vue&type=style&index=0&id=28bf8124&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bf8124",
  null
  
)

export default component.exports