<template>
  <div class="homeIndexBanner">
    <div class="bannerContent">
      <el-carousel
        dots="none"
        trigger="click"
        :arrow="dataList.length > 1 ? 'hover' : 'never'"
        :indicator-position="dataList.length > 1 ? '' : 'none'"
      >
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
          <div
            class="bannerInner"
            :style="{ 'background-image': 'url(' + item.url + ')' }"
            :class="{
              pointer: item.link_url && item.extend && !item.extend.is_show,
            }"
            @click="handleDivLink(item)"
          >
            <div
              v-if="item.extend && item.extend.is_show"
              class="bannerBtn"
              :class="'btnStyle' + item.extend.buttonStyle"
              :style="{
                bottom: `${item.extend.bottom}%`,
                right: `${item.extend.right}%`,
                background: item.extend.backgroundColor,
                borderColor: item.extend.borderColor,
              }"
              @click="handleLink(item.link_url)"
            >
              <el-button
                type="text"
                :style="{
                  fontSize: `${item.extend.remSize}px`,
                  color: item.extend.color,
                }"
                >{{ item.extend.content }}</el-button
              >
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  name: "homeIndexBanner",
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
      };
      let res = await this.$store.dispatch("home/getHomeBanner", params);
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
          item.extend.remSize = this.rootResize(item.extend.fontSize);
        });
        this.dataList = res.data;
      }
    },
    rootResize(val) {
      var baseFontSize = val || 14;
      var baseWidth = 1920;
      var minWidth = 1200;
      var clientWidth =
        document.documentElement.clientWidth || window.innerWidth;
      var innerWidth = Math.max(Math.min(clientWidth, baseWidth), minWidth);
      var rem = clientWidth / (baseWidth / baseFontSize);
      rem = Math.round(innerWidth / (baseWidth / baseFontSize));
      return rem;
    },
    handleLink(link) {
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.homeIndexBanner {
  width: 100%;
  padding-top: 18.23%;
  position: relative;
  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .el-carousel {
      height: 100%;
      /deep/ .el-carousel__container {
        height: 100% !important;
        .bannerInner {
          height: 100%;
          background-size: 100% 100%;
          // cursor: pointer;
          position: relative;
          .bannerBtn {
            position: absolute;

            padding: 0px 20px;
            border: 1px solid;
            border-radius: 4px;
            transform: translate(50%, 50%);
            &.btnStyle1 {
              border-radius: 0px;
            }
            &.btnStyle2 {
              border-radius: 4px;
            }
            &.btnStyle3 {
              border-radius: 999px;
            }
          }
        }
      }
      /deep/ .el-carousel__button {
        width: 40px;
        height: 7px;
        border-radius: 2px;
       
      }
      /deep/ .el-carousel__arrow {
        width: 50px;
        height: 50px;
        font-size: 45px;
        background: transparent !important;
        i {
          font-weight: bold;
        }
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
