<template>
  <div class="imgSilder" :id="ranDomId">
    <el-carousel
      :initial-index="value1"
      :arrow="imgList.length > 1 ? 'always' : 'never'"
      dots="none"
      :height="imgOption.height"
      :autoplay="autoPlay"
      :indicator-position="imgList.length > 1 ? 'outside' : 'none'"
      trigger="click"
      :loop="true"
      :interval="4000"
    >
      <el-carousel-item
        v-for="(item, index) in imgList"
        :key="index"
        :name="index + ''"
      >
        <div
          class="clearfix"
          v-for="(ele, index) in item"
          :key="index"
          :style="divStyle"
        >
          <div class="fl demo-carousel">
            <div class="title cursor clearfix" @click="toNewsMessage(ele)">
              <div class="fl titleItem">
                {{ ele.title_en | priorFormat(ele.title_zh, LOCALE) }}
              </div>
            </div>
            <div class="issuer clearfix">
              <div class="fl">
                {{ ele.publisher_en | priorFormat(ele.publisher_zh, LOCALE) }}
              </div>
              <div class="fl created_time">
                {{ ele.created_time | secondFormat("LL") }}
              </div>
            </div>
            <div class="main" :class="LOCALE == 'en' ? 'main' : 'mainLine'">
              {{ ele.overview_en | priorFormat(ele.overview_zh, LOCALE) }}
            </div>
            <!-- <div class="main">{{ ele.content_en | priorFormat(ele.content_zh,LOCALE) }}</div> -->
          </div>
          <div class="fr" :class="{ newsImg: ele.video_id }">
            <div
              class="iconDiv cursor"
              @click="playVideo(ele.video_id)"
              v-show="ele.video_id"
            >
              <i class="el-icon-video-play iconPlay"></i>
            </div>
            <el-image
              :src="LOCALE == 'en' ? ele.poster_en : ele.poster_zh"
              style="width: 500px; height: 281px"
              class="img"
            ></el-image>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <videoPlay ref="videoPlay"></videoPlay>
    <!-- <el-dialog fullscreen :visible.sync="showDialog" class="imgPreivewDialog" :show-close="false">
      <el-image
        class="diaLogImage"
        style="width:0px;height:0px;"
        :src="previewList[0]"
        :preview-src-list="previewList"
        @closePreview="showDialog=false"
        ref="diaLogImage"
      ></el-image>
    </el-dialog>-->
  </div>
</template>

<script>
import { splitArr } from "~/baseUtils";
import videoPlay from "@/baseComponents/videoPlay";

export default {
  components: { videoPlay },
  props: {
    autoPlay: {
      type: Boolean,
      default: false,
    },
    imgOption: {
      //width:'256px'
      //height:'256px',
      //totalWidth:600,
      //imgList:[]
    },
    isViewClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ranDomId: "",
      value1: 0,
      imgList: [],
      previewList: [],
      offset: 0,
      bigImg: "",
      imgModel: false,
      bigWidth: 0,

      bigHeight: 0,
      showDelete: false,
      showDialog: false,
      divStyle: {
        width: this.imgOption.width,
        height: this.imgOption.height,
      },
    };
  },

  mounted() {
    this.handleShow();
    this.ranDomId = `id${this.getKey()}`;
  },
  activated() {
    this.handleShow();
  },
  methods: {
    // 播放视频
    playVideo(video) {
      this.$refs.videoPlay.startPlay(video);
    },
    visitPreview(ele) {
      if (this.isViewClick) {
        this.$emit("viewChange", ele);
      } else {
        this.showDialog = true;
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.diaLogImage.clickHandler(ele.src);
          });
        });
      }
    },
    getKey() {
      return Math.random().toString(36).substr(2);
    },
    toNewsMessage(item) {
      this.$router.push({
        path: "newsMessageDetails",
        query: {
          parameter: this._encode({
            meeting_news_id: item.meeting_news_id,
          }),
        },
      });
    },
    handleShow() {
      let arr = [];
      this.imgOption.imgList.forEach((it) => {
        arr.push(it.src);
      });
      this.previewList = arr;
      let oneWidth = parseInt(this.imgOption.width);
      let totalWidth = this.imgOption.totalWidth;
      let arrSize = parseInt(totalWidth / oneWidth);
      this.divStyle = {
        width: this.imgOption.width,
        height: this.imgOption.height,
        marginRight:
          parseInt(
            (parseFloat("0." + (totalWidth / oneWidth + "").substr(2)) *
              oneWidth) /
              (arrSize - 1)
          ) + "px",
      };
      let handleImgList = splitArr(this.imgOption.imgList, arrSize);

      this.imgList = handleImgList;
      let position = (1100 - this.imgList.length * 14) / 2 - 20;
      if (this.imgList.length > 1) {
        this.$nextTick(() => {
          document.querySelector(
            `#${this.ranDomId} .el-carousel__arrow--left`
          ).style.left = `${position}px`;
          document.querySelector(
            `#${this.ranDomId} .el-carousel__arrow--right`
          ).style.right = `${position}px`;
        });
      }
    },
  },
  watch: {
    "imgOption.imgList": {
      deep: true,
      handler(nv) {
        this.handleShow();
      },
    },
  },
};
</script>

<style  scoped lang='less'>
.imgPreivewDialog {
  /deep/ .el-dialog {
    border-radius: 0px;
    background: transparent;
  }
  /deep/ .el-dialog__header {
    background: transparent;
    border-radius: 0px;
    border-bottom: 0 none;
  }
}
.diaLogImage {
  background: transparent;
  /deep/ & > img {
    display: none;
  }
}
.newsImg {
  position: relative;
  .iconDiv {
    position: absolute;
    top: 0;
    height: 280px;
    width: 500px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    &:hover {
      background: rgba(0, 0, 0, 0.5);
      i {
        color: #ececec;
      }
    }
    .iconPlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-size: 45px;
      color: #dbd8d8;
    }
  }
}

.imgSilder {
  width: 100%;
  /deep/.el-carousel__indicators--outside {
    position: absolute;
    left: 0;
    top: 280px;
  }
  /deep/ .el-carousel__indicator--horizontal {
    .el-carousel__button {
      width: 15px;
      height: 15px;
      background: #d1d1d1;
      border-radius: 0;
      border: 1px solid #0f288a;
    }
    &.is-active {
      .el-carousel__button {
        background: #0f288a;
      }
    }
  }

  /deep/ .el-carousel__arrow {
    width: 40px;
    height: 40px;
    bottom: -82px;
    background: #000;
    opacity: 0.2;
    display: none;
  }
  // /deep/ .el-carousel__arrow--left {
  //   left: 0px;
  //   top: unset;
  //   .el-icon-arrow-left {
  //     font-weight: 600;
  //     color: #fff;
  //     font-size: 20px;
  //   }
  // }
  // /deep/ .el-carousel__arrow--right {
  //   right: 0px;
  //   top: unset;
  //   .el-icon-arrow-right {
  //     font-weight: 600;
  //     color: #fff;
  //     font-size: 20px;
  //   }
  // }
  .demo-carousel {
    width: 56%;
    .title {
      color: #333333;
      font-size: 18px;
      margin-top: 30px;
      .titleItem {
        width: 80%;
        &:hover {
          color: #3b9af0;
        }
      }
    }
    .main {
      margin-top: 24px;
      font-size: 15px;
      text-indent: 2em;
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
    .mainLine {
      line-height: 24px;
    }
  }
  .newsRight {
    width: 30%;
    position: relative;
    .mark {
      width: 500px;
      height: 281px;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s;
      .el-icon-video-play {
        font-size: 34px;
        color: #aaa;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .mark:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
      color: #fff;
    }
  }
  .issuer {
    margin-top: 16px;

    .created_time {
      color: #999999;
      margin-left: 20px;
    }
  }
  .eletext {
    background: #fff;
    height: 60px;
    position: relative;
    text-align: center;
    padding: 10px;
  }
  .fl {
    position: relative;
  }
}
/deep/.el-carousel--horizontal {
  overflow: hidden;
}
</style>
