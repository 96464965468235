<template>
  <div class="atfActivityNews content_area clearfix">
    <div class="titleNews">{{ $t("newsInformation") }}</div>
    <newSlider
      v-if="imgOption.imgList.length > 0"
      :imgOption="imgOption"
      :autoPlay="true"
      :isViewClick="true"
      @viewChange="handleClick"
    ></newSlider>
    <noDataImg v-else></noDataImg>
  </div>
</template>
<script>
import newSlider from "~exp/components/home/newSlider";

export default {
  components: {
    newSlider,
  },
  data() {
    return {
      newsImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/home1.jpg",
      imgOption: {
        width: "1300",
        height: "310px",
        totalWidth: 1300,
        imgHeight: "250px",
        imgList: [],
      },
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    playVideo() {
      this.$refs.videoPlay.startPlay("1a8a33ff7cdd4adcbfa18632a11430c1");
    },
    handleClick(item) {},
    async getList() {
      let params = {
        meeting_id: this.MEETING_ID,
        is_show: 1,
      };
      let meetData = await this.$store.dispatch("home/getMeetNewsList", params);
      if (meetData.success) {
        this.imgOption.imgList = meetData.data;
      }
    },
  },
};
</script>
<style scoped lang="less">
.atfActivityNews {
  padding-bottom: 24px;
  margin-top: 50px;
  min-height: 300px;
  position: relative;
  .newsLeft {
    width: 50%;
    .title {
      color: #333333;
      font-size: 18px;
      margin-top: 50px;
      &:hover {
        color: #3b9af0;
      }
    }
    .main {
      margin-top: 30px;
      font-size: 15px;
      text-indent: 2em;
    }
  }
  .titleNews {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 16px;
  }
  .newsRight {
    width: 500px;
    position: relative;
    .mark {
      width: 500px;
      height: 281px;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s;
      .el-icon-video-play {
        font-size: 34px;
        color: #aaa;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .mark:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
      color: #fff;
    }
  }
}
</style>
