<template>
  <div class="content_area containerBox">
    <div class="title">{{ $t("brightExhibitor") }}</div>
    <div class="list">
      <div
        class="item"
        :class="LOCALE === 'en' ? 'activeItem' : ''"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <el-image :fit="'scale-down'" :src="item.img" class="img"></el-image>
        <el-image
          :fit="'scale-down'"
          :src="item.imgActive"
          class="imgActive"
        ></el-image>
        <div class="textBox">
          <div class="text" :class="LOCALE === 'en' ? 'engText' : ''">
            {{ item.text }}
          </div>
          <div class="textActive">{{ item.textActive }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "brightExhibitor",
  data() {
    return {
      dataList: [
        {
          text: this.$t("hotExhibitorTitle1"),
          textActive: this.$t("hotExhibitorConent1"),
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/1.jpg",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/active1.jpg",
        },
        {
          text: this.$t("hotExhibitorTitle2"),
          textActive: this.$t("hotExhibitorConent2"),
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/2.jpg",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/active2.jpg",
        },
        {
          text: this.$t("hotExhibitorTitle3"),
          textActive: this.$t("hotExhibitorConent3"),
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/3.jpg",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/active3.jpg",
        },
        {
          text: this.$t("hotExhibitorTitle4"),
          textActive: this.$t("hotExhibitorConent4"),
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/4.png",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/brightExhibitor/active4.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.containerBox {
  padding-bottom: 60px;
  .title {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    .item {
      width: 310px;
      height: 410px;
      position: relative;
      .img {
        width: 310px;
        height: 410px;
        transition: 0.5s;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
      .imgActive {
        width: 310px;
        height: 410px;
        transition: 0.5s;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
      .textBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 310px;
        height: 410px;
        overflow: hidden;
        .text {
          position: absolute;
          top: 0;
          left: 0;
          padding: 0 23px;
          display: flex;
          align-items: center;
          color: #ffffff;
          width: 310px;
          height: 410px;
          font-size: 24px;
          font-weight: 800;
          justify-content: center;
          transition: 0.5s;
          opacity: 1;
          cursor: default;
        }
        .engText {
          font-size: 20px;
        }
        .textActive {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          line-height: 150%;
          padding: 0 23px;
          display: flex;
          align-items: center;
          color: #ffffff;
          width: 310px;
          height: 410px;
          justify-content: center;
          transition: 0.5s;
          opacity: 0;
          cursor: default;
        }
        .textLinkHeight {
          line-height: 100%;
        }
      }
      &:hover {
        .img {
          opacity: 0;
        }
        .imgActive {
          opacity: 1;
        }
        .text {
          opacity: 0;
        }
        .textActive {
          opacity: 1;
        }
      }
    }
    .activeItem:first-child:hover .textActive {
      line-height: 100%;
      transition: 0.3s;
    }
  }
}
</style>