<template>
  <div class="content_area containerBox">
    <div class="title">
      {{ $t("supportUnit") }}
      <span class="hint" v-if="LOCALE === 'zh'">{{ $t("supportUnit1") }}</span>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <el-image
          :src="item.img"
          class="img"
          :fit="'scale-down'"
          :class="{ cursor: item.url }"
          @click="toWebsite(item.url)"
        ></el-image>
        <!-- <div class="text">{{ item.text }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "supportUnit",
  data() {
    return {
      dataList: [
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo1.png",
          text: this.$t("supportUnit2"),
        },
        {
          img:  'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo2.png',
          text: this.$t('supportUnit3')
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo3.png",
          text: this.$t("supportUnit4"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo4.png",
          text: this.$t("supportUnit5"),
          url: "https://mp.weixin.qq.com/s/zDZ-CbXYdvrj22--GbDGYA",
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo5.png",
          text: this.$t("supportUnit6"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo6.png",
          text: this.$t("supportUnit7"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo7.png",
          text: this.$t("supportUnit8"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo8.png",
          text: this.$t("supportUnit9"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo9.png",
          text: this.$t("supportUnit10"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo10.png",
          text: this.$t("supportUnit11"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo11.png",
          text: this.$t("supportUnit12"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo12.png",
          text: this.$t("supportUnit13"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo13.png",
          text: this.$t("supportUnit14"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/supportUnit/logo14.png",
          text: "客家物流联盟",
        },
        // {
        //   img:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expoApplent/home/supportUnit14.png",
        //   text: this.$t("supportUnit15"),
        //   url: "https://mp.weixin.qq.com/s/zDZ-CbXYdvrj22--GbDGYA",
        // },
      ],
    };
  },
  methods: {
    toWebsite(url) {
      if (url) {
        // window.location.href = url;
        window.open(url);
      }
    },
  },
};
</script>

<style scoped lang="less">
.containerBox {
  padding-bottom: 54px;
  .title {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;
    .hint {
      color: #909399;
      font-weight: 800;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 16px;
      margin-right: 22px;
      width: 242px;

      .img {
        width: 242px;
        height: 70px;
      }
      .text {
        text-align: center;
        word-break: keep-all;
      }
    }
    .item:nth-child(5n) {
      margin-right: 0;
    }
  }
}
</style>