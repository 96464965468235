var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeIndexBanner"},[_c('div',{staticClass:"bannerContent"},[_c('el-carousel',{attrs:{"dots":"none","trigger":"click","arrow":_vm.dataList.length > 1 ? 'hover' : 'never',"indicator-position":_vm.dataList.length > 1 ? '' : 'none'}},_vm._l((_vm.dataList),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"bannerInner",class:{
            pointer: item.link_url && item.extend && !item.extend.is_show,
          },style:({ 'background-image': 'url(' + item.url + ')' }),on:{"click":function($event){return _vm.handleDivLink(item)}}},[(item.extend && item.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + item.extend.buttonStyle,style:({
              bottom: `${item.extend.bottom}%`,
              right: `${item.extend.right}%`,
              background: item.extend.backgroundColor,
              borderColor: item.extend.borderColor,
            }),on:{"click":function($event){return _vm.handleLink(item.link_url)}}},[_c('el-button',{style:({
                fontSize: `${item.extend.remSize}px`,
                color: item.extend.color,
              }),attrs:{"type":"text"}},[_vm._v(_vm._s(item.extend.content))])],1):_vm._e()])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }