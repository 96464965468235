<template>
  <div class="content_area containerBox" v-if="dataList.length">
    <div class="titleBox">
      <div class="title">{{ lable_title }}</div>
      <div class="more" @click="gotoMore">
        {{ $t("more") }} <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        @click="gotoLook(item)"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <el-image :src="item.live_img || img_url" class="coverImg"></el-image>
        <div class="footBox">
          <div class="companyName" :title="item.name">
            <span class="point">{{
              item.issue_en | priorFormat(item.issue_zh, LOCALE)
            }}</span>
          </div>
          <div class="flexBox timeBox">
            {{ item.start_time | secondFormat("LL") }}
            {{ item.start_time | secondFormat("T") }} -
            {{ item.end_time | secondFormat("T") }}
          </div>
        </div>
        <div class="status">
          <div class="must" v-if="item.status == 2">
            <span>•</span>{{ $t("begining") }}
          </div>
          <div class="now" v-if="item.status == 1">
            <i class="el-icon-s-data"></i> {{ $t("itslive") }}
          </div>
          <div class="end" v-if="item.status == 3">
            {{ $t("meetingReturnLive") }}
          </div>
        </div>
      </div>
    </div>
    <videoPlay ref="videoPlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from "~/baseComponents/videoPlay";
export default {
  props:{
    lable_title:{
      type:String,
      default:function () {
        return this.$t("liveRecommendation")
      }
    },
    //1未开始 2进行中 3 已结束
    liveType:{
      type:Number,
      default:0
    }
  },
  components: { videoPlay },
  name: "hotExhibitor",
  data() {
    return {
      dataList: [],
      img_url: "",
    };
  },
  mounted() {
    this.img_url =
      this.LOCALE == "en"
        ? "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverEn.jpg"
        : "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverZh.jpg";
    this.getList();
  },
  methods: {
    async getList() {
      let data = await this.$store.dispatch("baseStore/getHotVideoList", {
        meeting_id: this.MEETING_ID,
        now_time: this.$moment().unix(),
        limit: 4,
      });
      console.log(data);
      data.data.forEach((element) => {
        let nowTime = this.$moment().unix();
        if (element.start_time <= nowTime && nowTime <= element.end_time) {
          element.status = 1;
        }
        if (nowTime < element.start_time) {
          element.status = 2;
        }
        if (nowTime > element.end_time) {
          element.status = 3;
        }
      });
      if(this.liveType === 3){
        this.dataList = data.end_list;
      }else{
        this.dataList = data.data;
      }
      
    },
    gotoMore() {
      this.$router.push({
        path: "/negotiates",
      });
    },
    gotoLook(item) {
      let time = moment(new Date()).unix();
      if (item.agenda_type == 2) {
        if (item.end_time < time) {
        } else {
          if (item.start_time - 600 < item.now_time) {
            let params = _encode({
              channel_id: item.channel_id,
              user_id: this.USER_INFO.id,
              anchor_id: item.guests,
            });
            let url = `/networkTalking/groupChat?initialParams=${this.USER_INFO.id}&parameter=${params}`;
            this.openAiLaWebsite(url);
          } else {
            this.$message.info("请提前10分钟进入");
          }
        }
      } else if (item.agenda_type == 1) {
        if (item.end_time < time) {
          //直播已结束
          if (item.playback_url && item.playback_url.indexOf("http") != -1) {
            window.open(item.playback_url);
            return;
          }
          if (item.playback_url) {
            if (this.IS_LOGIN) {
              this.$refs.videoPlay.startPlay(item.playback_url);
            } else {
              this.$router.push("/sign");
            }
            return;
          }
          if (item.watch_url) {
            window.open(item.watch_url);
            return;
          }
        } else {
          window.open(item.watch_url);
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.status {
  position: absolute;
  right: 0px;
  top: 10px;

  div {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-right: 10px;
    color: #fff;
  }
  .must {
    span {
      color: #3cce21;
      padding-right: 5px;
    }
  }
  .now {
    i {
      color: #ff1212;
      padding-right: 5px;
    }
  }
}
.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more {
    font-size: 15px;
    color: #999;
    cursor: pointer;
  }
}
.containerBox {
  padding-bottom: 60px;
  .title {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    .item {
      cursor: pointer;
      background: #fff;
      position: relative;
      width: 310px;
      height: 274px;
      // border: 1px solid #e9e9e9;
      .coverImg {
        width: 310px;
        height: 186px;
      }
      .footBox {
        padding: 14px 16px 0;
        .companyName {
          width: 278px;
          color: #4c4c4c;
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .flexBox {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          .exhibitor {
            color: #666666;
          }
        }
        .timeBox {
          color: #9999;
        }
      }
    }
  }
  .color717171 {
    color: #717171;
  }
}
</style>