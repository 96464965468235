<template>
  <div class="content_area containerBox">
    <div class="titleBox">
      <div class="title">{{ lable_title }}</div>
      <div class="more" @click="gotoMore">
        {{$t('more')}} <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="list">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <div class="status">
          <!-- <div class="onlie" v-if="item.online_status==1" style="color: #45ca16;">{{$t('status_onLine')}}</div>
          <div class="onlie" v-if="item.online_status==2" style="color:#E6A23C">{{$t('status_Busy')}}</div>
          <div class="onlie" v-if="item.online_status==3" style="color:#909399">{{$t('status_offLine')}}</div> -->
        </div>
        <el-image
          :fit="'contain'"
          :src="item.logo || img_url"
          class="coverImg"
        ></el-image>
        <div class="footBox">
          <div class="companyName" :title="item.name">
            <span class="point" @click="handleCompany(item)">{{
              item.company_name_en | priorFormat(item.company_name_zh, LOCALE)
            }}</span>
          </div>
          <div class="flexBox">
            <div class="color717171 address">
               {{
                    item.country
                      | countryCityFormat(
                        item.region_en,
                        item.region_zh,
                        LOCALE
                      )
                  }}
            </div>
            <div class="color717171">
              {{ $t("booth") }}：<span class="exhibitor">{{ item.booth_no }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props:{
    lable_title:{
      type:String,
      default:function() {
        return this.$t("hotExhibitor")
      }
    }
  },
  data() {
    return {
      dataList: [
       
      ],
      img_url: "",
    };
  },
  mounted() {
    this.img_url =
      this.LOCALE == "en"
        ? "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverEn.jpg"
        : "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverZh.jpg";
    this.getList();
  },
  methods: {
    async getList() {
      let data = await this.$store.dispatch("baseStore/getHotBoothList", {
        meeting_id: this.MEETING_ID,
        booth_type: 2,
        limit: 5,
      });
      this.dataList = data.data;
    },
    handleCompany(item) {
       const { href } = this.$router.resolve({
        path: "/boothDetail",
        query: {
          company_id: item.company_id,
          booth_id: item.booth_id,
        },
      });
      window.open(href, "_blank");
    },
    gotoMore() {
      this.$router.push({
        path: "/pavilion",
      });
    },
  },
};
</script>

<style scoped lang="less">
.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more {
    font-size: 15px;
    color: #999;
    cursor: pointer;
  }
}
.containerBox {
  padding-bottom: 60px;
  .title {
    color: #333333;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    .item {
      position: relative;
      width: 250px;
      //   height: 274px;
      padding-bottom: 20px;
      border: 1px solid #e9e9e9;
      .status {
        position: absolute;
        z-index: 99;
        position: absolute;
        right: 0;
        top: 20px;
        div {
          width: 50px;
          height: 20px;
          font-size: 12px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background: rgba(255, 255, 255, 0.8);
          text-align: center;
          line-height: 20px;
          font-weight: 900;
        }
        .onlie {
          
        }
      }
      .coverImg {
        width: 250px;
        height: 150px;
      }
      .footBox {
        padding: 14px 16px 0;
        .companyName {
          width: 218px;
          color: #4c4c4c;
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .flexBox {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          .exhibitor {
            color: #666666;
          }
        }
      }
    }
  }
  .address{
      width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .color717171 {
    color: #717171;
    cursor: pointer;
  }
}
</style>